import React from 'react';
import {usePdfComponents} from '../pages/report/pdf-hooks';
import {StyleSheet} from '@react-pdf/renderer';
import {toDateString} from '../util/str-util';
import {mFont, mFontOld, summaryFont} from '../styles/report-font';
import { getMultiplyValue } from '../styles/styleSheet';

interface TableTitleProps {
    title?: string;
    refer?: string;
    type?: string;
    dateOrUnit?: React.ReactNode;
    gridStyle?: any;
    tableStyle?: any;
    children: React.ReactNode;
    oldVerFont?: boolean;
}

const TableTitle = ({title, refer, type = 'summary', dateOrUnit, gridStyle, tableStyle, children, oldVerFont = false}: TableTitleProps) => {

    const {Text, View, Image, isPdf} = usePdfComponents();
    const isPdf1 = type === 'summary' && isPdf;
    const isMain = type === 'main';
    const num = getMultiplyValue(isPdf);

    const numByType = isPdf1 || isMain ? 1 : num;

    const styles = StyleSheet.create({
        tableTitle: {
            width: '100%',
            marginBottom: 40 * numByType,
        },
        titleArea: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexDirection: 'row',
            width: '100%',
            paddingBottom: 10 * numByType,
        },
        title: {
            fontSize: isMain ? 18 : 17 * numByType,
            fontWeight: 700,
            paddingLeft: 3 * numByType
        },
        titleImg: {
            height: 15 * numByType,
            marginRight: 5 * numByType
        },
        refer: {
            fontSize: isMain ? 14 : 13 * numByType,
        },
        dateOrUnit: {
            display: 'flex',
            alignItems: 'center',
            fontSize: isMain ? 14 : 13 * numByType,
            color: isMain ? '#000' : '#808080'
        },
        gridArea: {
            fontSize: isMain ? (oldVerFont ? mFontOld : mFont) : summaryFont * numByType,
            width: '100%'
        }
    });

    return (
        <View style={[styles.tableTitle, tableStyle]}>
            <View style={styles.titleArea}>
                {title != null ?
                    <Text style={styles.title}><Image style={styles.titleImg}
                                                      src={`/images/${type}_table_icon.png`}/> {title}</Text>
                    : <Text/>
                }
                {refer != null ?

                    isPdf1 ?
                        <Text style={styles.dateOrUnit}>조회일시: {toDateString(new Date())}</Text>
                        : <Text style={styles.refer}>데이터 출처: {refer}</Text>

                    : <Text style={styles.dateOrUnit}>{dateOrUnit}</Text>
                }
            </View>
            <View style={[styles.gridArea, gridStyle]}>
                {children}
            </View>
        </View>
    );
};

export default TableTitle;