import styles from './family-share-plan.module.scss';
import classNames from 'classnames/bind';
import {Field, FieldArray, Form, Formik, FormikValues, useFormikContext} from "formik";
import CustomerDetailPop from "./customer/customer-detail-pop";
import {CustomerDetailTab} from "../data/const_list";
import {InputBox, mdmRelList, NumberInputBox, SelectInputBox, SelectInputBox2} from "./customer/edit-common";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useInjection} from "inversify-react";
import {DialogManager} from "../components/dialog-manager";
import {CoReportDto} from "../model/co-report/dto/co-report-dto";
import {CoReportApi} from "../model/co-report/co-report-api";
import SelectButton from "../components/select-button";
import DirectInputPop from "../components/direct-input-pop";
import {CustomerApi} from "../model/customer/customer-api";
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {useMountedState} from "react-use";
import usePromise from "../model/common/use-promise";
import {PrCustomerRawData} from "../model/customer/dto/customer-raw-data";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";
import {asyncCall} from "../util/util";
import {toast} from "react-toastify";
import {strSaved} from "../data/strings";
import {ShareholderInfo} from "../model/customer/dto/shareholder-info";
import {SelectItem} from "../model/types";
import {getGiverList} from "./report/report-util";

const cx = classNames.bind(styles);

interface StockTransferPlanProps {
    data: CoReportDto
    setData: Dispatch<SetStateAction<CoReportDto | undefined>>
    onClose: VoidFunction
}

interface TransferDesignBoxProps {
    index: number;
    remove: (index: number) => any;
    shareholder: ShareholderInfo[];
    giverList: SelectItem[];
    issStkCn: number;
    validate: boolean;
}

const workerCheckList: SelectItem[] = [{id: true, title: "임직원"}, {id: false, title: "해당없음"}];

const CheckChangeStockTransferPlan = ({shareholder, setGiverList}: {shareholder: ShareholderInfo[], setGiverList: Dispatch<SetStateAction<SelectItem[]>>;}) => {
    const {values} = useFormikContext<FormikValues>();

    useEffect(() => {
        setGiverList(getGiverList(shareholder, values.sp ?? []));
    }, [shareholder]);

    return (
        <div></div>
    );
};

const TransferDesignBox = ({index, remove, giverList, issStkCn, validate}:TransferDesignBoxProps) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const dialogManager = useInjection(DialogManager);
    const [nameVal, setNameVal] = useState('');
    const prefix = `sp.${index}`;

    useEffect(() => {
        if (values.sp[index].transferShare)
            setFieldValue(prefix + '.transferRate', (values.sp[index].transferShare / issStkCn * 100).toFixed(2));
        else
            setFieldValue(prefix + '.transferRate', "0");
    }, [values.sp[index].transferShare, issStkCn]);


    const showAddAssignee = () => {
        dialogManager.open(
            DirectInputPop, {
                title: '양수자 직접 입력',
                width: '22.4rem',
                placeholder: '양수자 입력',
                fieldName: `name`,
                setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => onChangeName(value, value)
            }
        );
    };

    const onChangeName = (id: string, title: string) => {
        setFieldValue(prefix + '.' + nameVal, id);
    }

    return(
        <div className={cx('transfer-design-box')} id={index.toString()}>
            <div className={cx('line')}>
                <SelectInputBox2 title='양도자' selectList={giverList} name={`${prefix}.giverName`}/>
                <SelectButton
                    data={giverList}
                    title='양수자 선택'
                    addBtnText='직접 입력'
                    addList={showAddAssignee}
                    onSelect={onChangeName}
                    cn={[cx('select-btn')]}
                    listWidth='13.5rem'>
                    <InputBox
                        title={`양수자`}
                        name={`${prefix}.name`}
                        inputWidth='13.5rem'
                        placeholder='선택'
                        inputboxCn={`${cx('select-btn-input')} ${validate && values.sp[index].giverName && !values.sp[index].name? 'error' : ''}`}
                        onClick={() => setNameVal('name')} readOnly/>
                </SelectButton>
                <SelectInputBox title='관계' selectList={mdmRelList} name={`${prefix}.relation`} inputboxCn={`${validate && values.sp[index].giverName && !values.sp[index].relation? 'error' : ''}`}/>
            </div>
            <div className={cx('line')}>

                <NumberInputBox title={`양도 주식수`}
                                name={`${prefix}.transferShare`}
                                unit='주'
                                inputboxCn={`${cx('input-box')} ${validate && values.sp[index].giverName && !values.sp[index].transferShare? 'error' : ''}`}/>

                <InputBox title='양도 지분율' unit='%' name={`${prefix}.transferRate`} readOnly inputboxCn={`${cx('input-box')} ${validate && values.sp[index].giverName && !values.sp[index].transferRate? 'error' : ''}`}/>

                <NumberInputBox title={`1주당 취득가`}
                                name={`${prefix}.acquisitAmt`}
                                unit='원'
                                inputboxCn={`${cx('input-box')} ${validate && values.sp[index].giverName && !values.sp[index].acquisitAmt? 'error' : ''}`}/>

            </div>
            <div className={cx('line', 'line2')}>
                <SelectButton
                    data={workerCheckList}
                    title='양도자 임직원 여부'
                    onSelect={onChangeName}
                    cn={[cx('select-btn')]}
                    listWidth='20rem'>
                    <InputBox
                        title={`양도자 임직원 여부`}
                        name={`${prefix}.isWorkerGiver`}
                        inputWidth='20rem'
                        inputboxCn={`${cx('select-btn-input', 'long')} ${validate && values.sp[index].giverName && values.sp[index].isWorkerGiver === undefined? 'error' : ''}`} readOnly>
                        <Field
                            value={workerCheckList.find((li) => li.id === values.sp[index].isWorkerGiver)?.title ?? ''}
                            className={`input-txt ${cx('select-btn-input', 'long')}`} placeholder='선택'
                            onClick={() => setNameVal('isWorkerGiver')} readOnly/>
                    </InputBox>
                </SelectButton>
                <SelectButton
                    data={workerCheckList}
                    title='양수자 임직원 여부'
                    onSelect={onChangeName}
                    cn={[cx('select-btn')]}
                    listWidth='20rem'>
                    <InputBox
                        title={`양수자 임직원 여부`}
                        name={`${prefix}.isWorker`}
                        inputWidth='20rem'
                        inputboxCn={`${cx('select-btn-input', 'long')} ${validate && values.sp[index].giverName && values.sp[index].isWorkerGiver === undefined? 'error' : ''}`} readOnly>
                        <Field
                            value={workerCheckList.find((li) => li.id === values.sp[index].isWorker)?.title ?? ''}
                            className={`input-txt ${cx('select-btn-input', 'long')}`} placeholder='선택'
                            onClick={() => setNameVal('isWorker')} readOnly/>
                    </InputBox>
                </SelectButton>
            </div>
            <button className={cx('remove-btn')} onClick={() => remove(index)} type='button'></button>
        </div>
    );
};

const StockTransferPlan = ({data, setData, onClose}: StockTransferPlanProps) => {
    const customerApi = useInjection(CustomerApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const isMounted = useMountedState();
    const dialogManager = useInjection(DialogManager);
    const reportApi = useInjection(CoReportApi);
    const [validate, setValidate] = useState(false);

    const {clearInfo} = useCustomerInputClear(setData);

    const customer = data.customer;
    const [shareholder, setShareholder] = useState(customer.shareholder.list ?? []);
    const [giverList, setGiverList] = useState<SelectItem[]>([]);

    const [loading, rowDataResult] = usePromise(() => customerApi.rawData(data.customer.id), [data]);

    if (loading || !rowDataResult) return <div></div>;
    if (!customer) return null;

    const rawData: PrCustomerRawData = rowDataResult?.val!;

    const issStkCn = Number(customer.companyInfo.issStkCn);

    return(
        <Formik
            initialValues={{sp: rawData.stockTransferPlan.length === 0 ? [{}] : rawData.stockTransferPlan}}
            onSubmit={(values) => {
                setValidate(true);

                if (values.sp.some((info) => info.giverName && (!info.name || !info.relation || !info.transferShare || !info.acquisitAmt || info.isWorkerGiver === undefined || info.isWorker === undefined))) {
                    dialogManager.alert('양도자∙양수자 미입력 정보를 확인해 주세요.');
                    return;
                }

                if (values.sp.some((info) => (info.transferRate ?? 0) > 100)) {
                    dialogManager.alert('양도 주식수가 전체 주식수를 초과하였습니다');
                    return;
                }

                asyncCall(customerInputApi.saveStockTransferPlan, [customer.id, values.sp], result => {
                    setData(result);
                    toast.success(strSaved);
                }, isMounted, (e) => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}>
            {({values, setFieldValue, resetForm}) =>
                <Form className={cx('inheritance-tax-info')}>
                    <CheckChangeStockTransferPlan shareholder={shareholder} setGiverList={setGiverList}/>
                    <FieldArray name={'sp'}>
                        {({push, remove}) => <>
                            <div className={cx('btn-area')}>
                                <button
                                    onClick={() => {
                                        setValidate(false);
                                        push({});
                                    }}
                                    className={cx('add-btn')} type='button'>
                                    <i></i>설계 추가
                                </button>
                                <button
                                    className={cx('save-btn')}
                                    type='submit'>
                                    <i></i>저장하기
                                </button>
                                <button className='close-btn' type='button' onClick={() => onClose()}>닫기</button>
                            </div>
                            <div className={cx('scroll-box')}>
                                <div className={cx('scroll-area')}>
                                    <div className={cx('title-line')}>
                                        <p className={cx('title')}>
                                            주식이동 설계
                                            <button className={cx('reset-btn')} type='button'
                                                    onClick={() => {
                                                        clearInfo(customer.id, 'salary',
                                                            (report, rawData) => resetForm({values: {sp: rawData?.stockTransferPlan!}})
                                                        )
                                                    }}><i></i>초기화
                                            </button>
                                        </p>
                                        <button className={cx('status-btn')} type='button' onClick={() => {
                                            dialogManager.open(CustomerDetailPop, {
                                                customerId: data.customer.id,
                                                tab: CustomerDetailTab.Member,
                                                onDialogClose: async () => {
                                                    const updateResult = await reportApi.coReportV2(data.customer.id!);
                                                    if (updateResult.val) {
                                                        data.customer = updateResult.val!.customer;
                                                        setShareholder(updateResult.val!.customer.shareholder.list ?? []);
                                                        setData(updateResult.val!);
                                                    }
                                                }
                                            });
                                        }}>주요 주주현황<i></i></button>
                                    </div>

                                    {values.sp.map((_, i) =>
                                        <TransferDesignBox key={i} index={i}
                                                           shareholder={shareholder}
                                                           remove={idx => remove(idx)}
                                                           giverList={giverList}
                                                           issStkCn={issStkCn}
                                                           validate={validate}
                                        />
                                    )}

                                    <p className={cx('noti')}>※ 가족간 지분 이동시에는 <span>가족지분설계</span> 메뉴에서 입력해 주세요.</p>
                                </div>
                            </div>
                        </>}
                    </FieldArray>
                </Form>
            }
        </Formik>
    );
};

export default StockTransferPlan;