import styles from './payment-system-table-pop.module.scss';
import classNames from 'classnames/bind';
import React from "react";
import {useInjection} from "inversify-react";
import {GoodApi} from "../model/goods/good-api";
import usePromise from "../model/common/use-promise";
import {GoodsAction} from "../model/goods/dto/goods";
import {formatNum} from "../util/str-util";
import TextToHtml from "./TextToHtml";

const cx = classNames.bind(styles);


function goodActToSubTitle(act?: GoodsAction): string {
    switch (act) {
        case "NORMAL_SUB":
            return "컨설턴트를 위한 요금제";
        case "MANAGE_SUB":
            return "매니저를 위한 요금제";
        case "ONE_TIME_SS":
            return "필요시에만 구매";
    }

    return "";
}

const PaymentSystemTablePop = ({onClose}: { onClose: VoidFunction }) => {
    const goodApi = useInjection(GoodApi);

    const [loading, goods] = usePromise(() => goodApi.currentGoods(), [goodApi]);

    return (
        <div className='full-background'>
            <div className={`pop-inner ${cx('payment-sys-table')}`}>
                <div className={cx('top-line')}>
                    <div className={cx('title')}>
                        이용권 요금제 비교
                    </div>
                    <button className='close-btn' onClick={() => onClose()}>닫기</button>
                </div>
                <p className={cx('sub-title')}>씨오파트너의 요금제를 비교하고 결제를 진행해 주세요.</p>

                {
                    (loading || !goods) ? <div>Loading</div> :
                        <div className={cx('table')}>
                            <div className={cx('head')}>
                                <p></p>
                                {goods.val?.map((g) => <p key={g.id}>
                                    {/*{g.act === 'NORMAL_SUB' && <>컨설턴트용<br/>스마트 구독형</>}*/}
                                    {/*{g.act === 'MANAGE_SUB' && <>매니저용<br/>스마트 구독형</>}*/}
                                    {g.act === 'ANNUAL_PASS' && <>스마트 연간 이용권</>}
                                    {g.act === 'ONE_TIME_SS' && <>1회 이용권</>}
                                </p>)}
                            </div>
                            <div className={cx('body')}>
                                <p>상품종류</p>
                                {goods.val?.map((g) => <p key={g.id}>
                                    {/*{g.type === 'SUBSCRIPTION' && <>월 정기구독 상품</>}*/}
                                    {g.type === 'SINGLE' && (g.act === 'ANNUAL_PASS' ? <>연간 멤버십 상품</>: <>단건 구매 상품</>)}
                                </p>)}
                            </div>
                            <div className={cx('body')}>
                                <p>이용권 수</p>

                                {goods.val?.map((g) => <p key={g.id}>
                                    <span className={cx('bold')}>{g.meta.val2 ?? ""}건</span> <span>/

                                    {/*{g.type === 'SUBSCRIPTION' && <>월</>}*/}
                                    {g.type === 'SINGLE' && (g.act === 'ANNUAL_PASS' ? <>년</>: <>회</>)}
                                </span>
                                </p>)}
                            </div>
                            <div className={cx('body')}>
                                <p>요금</p>
                                {goods.val?.map((g) => <p key={g.id}>
                                    {formatNum({num: g.finalPrice, empty: '', type: 'txt'})}원
                                </p>)}
                            </div>
                            <div className={cx('body')}>
                                <p>연간 멤버십<br/>전용 메뉴</p>
                                {goods.val?.map((g) => <p key={g.id} className={cx('note')}>
                                    <span className={cx('dot-list')}>
                                    <span></span>
                                    {g.act === 'ANNUAL_PASS' ? <span>컨설팅 자료실, 온라인 강의실,<br/>전문가 Q&A 게시판, 카톡단체발송 서비스</span> : <span className={cx('no-content')}>-</span>}
                                    </span>
                                </p>)}
                            </div>
                            <div className={cx('body')}>
                                <p>유의사항</p>
                                {goods.val?.map((g) => <p key={g.id} className={cx('note')}>
                                    {g.content.noteSp?.split('\n').map((s, idx) =>
                                        <span key={idx} className={cx('dot-list')}><span>•</span><TextToHtml text={s}/></span>
                                    )}
                                </p>)}
                            </div>
                            <div className={cx('body2')}>
                                <p>공통사항</p>
                                <p className={cx('list')}>
                                    <span><i></i>기업 기본현황을 확인하고 고객 등록 가능하며, 고객등록(리포트 생성) 전까지는 이용권 차감되지 않아요.</span>
                                    <span><i></i>개인기업 고객등록시에는 이용권이 차감되지 않아요.</span>
                                    <span><i></i>50인 이상 단체회원은 고객센터로 별도 문의해 주세요.</span>
                                </p>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default PaymentSystemTablePop;