import React, {useEffect, useState} from 'react';
import styles from './company-value-info.module.scss';
import classNames from 'classnames/bind';
import {DateInputBox, InputBox, NumberInputBox, SelectInputBox} from './customer/edit-common';
import {Form, Formik} from 'formik';
import {useInjection} from 'inversify-react';
import {asyncCall} from '../util/util';
import {useMountedState} from 'react-use';
import {ReportAddInfoPopProps} from './report-add-info-pop';
import {toast} from 'react-toastify';
import {strSaved} from '../data/strings';
import * as Yup from 'yup';
import SelectButton from '../components/select-button';
import {SelectItem} from '../model/types';
import {DialogManager} from '../components/dialog-manager';
import AddListPop from '../components/add-list-pop';
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";
import {getFsRefYearV2} from "./report/report-util";
import TooltipPop from "../components/tooltip-pop";

const cx = classNames.bind(styles);

const reduceCapitalTypeList:SelectItem[] = [{id: 0, title: '유상증자'},{id: 1, title: '유상감자'},{id: 2, title: '이익소각'}];
const salesgrate1Txt = ['% 업종평균 증가율', '% 직전년도 증가율', '% 직전2년 평균증가율', '% 중소기업 평균증가율'];
const marginrate1Txt = ['% 업종평균 순이익률', '% 직전년도 순이익률', '% 직전2년 평균순이익률', '% 직전3년 평균순이익률', '% 중소기업 평균순이익률'];

const CompanyValueInfo = ({data, setData, onClose}: ReportAddInfoPopProps) => {
    const customerInputApi = useInjection(CustomerInputApi);
    const isMounted = useMountedState();
    const dialogManager = useInjection(DialogManager);
    const {clearInfo} = useCustomerInputClear(setData);

    // 유상증, 유상감(이익 소각 내역)
    const [capIncErr, setCapIncErrIdx] = useState<boolean[]>([false, false, false, false]);

    const [salesgrateList, setSalesgrateList] = useState<SelectItem[]>([]);
    const [marginrateList, setMarginrateList] = useState<SelectItem[]>([]);

    const [salesgrateAllList, setSalesgrateAllList] = useState<SelectItem[]>([]);
    const [marginrateAllList, setMarginrateAllList] = useState<SelectItem[]>([]);

    const years: number[] = [];
    const years2: number[] = [];
    const year = getFsRefYearV2(data);
    const year2 = (new Date()).getFullYear();

    for (let i = 0; i < 4; i++) {
        years[i] = year - 3 + i;
    }

    for (let i = 0; i < 4; i++) {
        years2[i] = year2 - 3 + i;
    }

    const evalYearList = [
        {id: year, title: `${year}.12.31`},
        {id: year + 1, title: `${year + 1}.12.31 (예상)`}
    ]
    
    const salesgrate1 = salesgrate1Txt.map((it, i) => {
        const ratio = data.report?.oi.salesgrate1[i];
        return {id: ratio, title: ratio + salesgrate1Txt[i]}
    });
    
    const marginrate1 = marginrate1Txt.map((it, i) => {
        const ratio = data.report?.oi.marginrate1[i];
        return {id: ratio, title: ratio + marginrate1Txt[i]}
    });

    useEffect(() => {
        if (!data.customer.eval)
            return;

        const sgList = [];
        const mgList = [];

        if (data.report?.oi.salesgrate1.every((v) => data.customer.eval.salesgrate1 !== v))
            sgList.push({id: data.customer.eval.salesgrate1, title: data.customer.eval.salesgrate1.toString()});

        if (data.report?.oi.marginrate1.every((v) => data.customer.eval.marginrate1 !== v))
            mgList.push({id: data.customer.eval.marginrate1, title: data.customer.eval.marginrate1.toString()});

        setSalesgrateList(sgList);
        setMarginrateList(mgList);
    }, [data.customer.eval]);

    useEffect(() => {
        setSalesgrateAllList(salesgrate1.concat(salesgrateList.map((it) => ({id: it.id, title: it.title + '% 직접입력'}))));
        setMarginrateAllList(marginrate1.concat(marginrateList.map((it) => ({id: it.id, title: it.title + '% 직접입력'}))));
    }, [salesgrateList, marginrateList])

    const onChangeRate = (field: string, val: string, setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void) => {
        if (val) {
            setFieldValue(field, val);
        }
        else {
            setFieldValue(field, field === "salesgrate1" ? salesgrate1[0].id : marginrate1[0].id);
        }
    }

    const getEvalYear = (year?: number) => {
        const selected = evalYearList.find((it) => it.id === year);

        return selected ? selected.title : evalYearList[0].title;
    }

    const footnote = (title: string, footnote: string) => <>{title}<span
        className='numbering'>{footnote ?? ''}</span></>;

    const customer = data.customer;
    if (!customer) return null;

    return (
        <Formik
            initialValues={{...customer.eval, capInc: customer.eval.capInc.map((c) => {
                if (c.capincShrs != null) {
                    c.capincShrs = Math.abs(c.capincShrs);
                }
                    return c;
                })}}
            validationSchema={Yup.object({
                capInc: Yup.array().of(
                    Yup.object({
                        capincDate: Yup.string().min(8, '8자로 입력해 주세요.').max(8, '8자로 입력해 주세요.').matches(/^[0-9]+$/, '숫자만 입력해 주세요.'),
                        capincShrs: Yup.number().typeError('숫자를 입력해 주세요.'),
                    })
                ),

            })}
            onSubmit={(values) => {

                const errIdx = values.capInc.map((cap) => {
                    if (cap.capincShrs || cap.capincDate || cap.issueValue) {
                        return !(cap.capincShrs && cap.capincDate && cap.issueValue);
                    }
                   return false;
                });

                if (errIdx.includes(true)) {
                    setCapIncErrIdx(errIdx);
                    return;
                }

                asyncCall(customerInputApi.saveEvalInfo, [customer.id, values], result => {
                    setData(result);
                    data.customer = result.customer;
                    toast.success(strSaved);
                }, isMounted, () => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}>

            {({values, setFieldValue, resetForm}) => {
                return <Form className={cx('company-value-info')}>
                    <div className={cx('btn-area')}>
                        {/* <button className='close-btn' type='button' onClick={() => clearInfo(customer.id, 'eval', (report) => resetForm({values: report.customer.eval}))}><i></i>초기화</button> */}
                        <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                        <button className='close-btn' type='button' onClick={() => {
                            onClose()
                        }}>닫기
                        </button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <p className={cx('title')}>기업가치 평가 정보
                                <button className={cx('reset-btn')} type='button'
                                        onClick={() => clearInfo(customer.id, 'eval',
                                            (report) => resetForm({values: report.customer.eval})
                                        )}><i></i>초기화
                                </button>
                            </p>
                            <div className={cx('input-area')}>
                                <SelectButton
                                    listWidth='52rem'
                                    top='76%'
                                    data={evalYearList}
                                    cn={[cx('select-btn')]}
                                    onSelect={(id, _) => setFieldValue('selectedYear', id)}>
                                    <InputBox
                                        title='평가기준일'
                                        inputWidth='52rem'
                                        inputboxCn={cx('input-box', 'select-box')}
                                        value={getEvalYear(values.selectedYear)}
                                        readOnly/>
                                </SelectButton>

                                <NumberInputBox title='성장기 (향후 지속기간)' name='gperiod' inputboxCn={cx('input-box')}
                                                unit='년'
                                                inputWidth='52rem'/>
                                <SelectButton
                                    listWidth='52rem'
                                    top='76%'
                                    data={salesgrateAllList}
                                    addList={() => dialogManager.open(AddListPop, {
                                        title: '매출액증가율 가정',
                                        width: '22.7rem',
                                        list: salesgrateList,
                                        setList: setSalesgrateList,
                                        hasUnit: true,
                                        fieldName: 'salesgrate1',
                                        setFieldValue: (field: string, val?: any) => onChangeRate(field, val, setFieldValue),
                                        isNum: true
                                    })}
                                    addBtnText='직접입력'
                                    onSelect={(id, _) => setFieldValue('salesgrate1', id)}
                                    cn={[cx('select-btn')]}>
                                    <InputBox
                                        title='성장기 매출액 증가율 가정'
                                        inputWidth='52rem'
                                        inputboxCn={cx('input-box', 'select-box')}
                                        value={salesgrateAllList.find((it) => it.id === values.salesgrate1)?.title}
                                        tooltip={[
                                            {
                                                title: '성장기 매출액 증가율 기본가정',
                                                desc: '매출액 증가율 기본가정은 다음의 조건에 따라 적용하였습니다.\n1순위) 업종평균 매출액증가율\n2순위) 중소기업평균 매출액증가율 (1순위값 마이너스일때)'
                                            },
                                            {
                                                title: '성장기 순이익률 기본가정',
                                                desc: '순이익률 기본가정은 다음의 조건에 따라 적용하였습니다.\n1순위) 업종평균 순이익률\n2순위) 중소기업평균 순이익률 (1순위 마이너스일때)'
                                            }
                                        ]}
                                        readOnly/>
                                </SelectButton>
                                <SelectButton
                                    listWidth='52rem'
                                    top='76%'
                                    data={marginrateAllList}
                                    addList={() => dialogManager.open(AddListPop, {
                                        title: '성장기 순이익률 가정',
                                        width: '22.7rem',
                                        list: marginrateList,
                                        setList: setMarginrateList,
                                        hasUnit: true,
                                        fieldName: 'marginrate1',
                                        setFieldValue: setFieldValue,
                                        isNum: true
                                    })}
                                    addBtnText='직접입력'
                                    onSelect={(id, _) => setFieldValue('marginrate1', id)}
                                    cn={[cx('select-btn')]}>
                                    <InputBox
                                        title='성장기 순이익률 가정'
                                        inputWidth='52rem'
                                        inputboxCn={cx('input-box', 'select-box')}
                                        value={marginrateAllList.find((it) => it.id === values.marginrate1)?.title}
                                        readOnly/>
                                </SelectButton>
                                <div className={cx('flex-input-box')}>
                                    <InputBox title='후기 매출액 증가율 가정' name='salesgrate2'
                                              inputboxCn={cx('input-box')}
                                              tooltip={[
                                                  {
                                                      title: '후기 매출액 증가율 기본가정',
                                                      desc: '매출액 증가율 기본가정은 다음의 조건에 따라 적용하였습니다.\n1순위) 업종평균 매출액증가율\n2순위) 중소기업평균 매출액증가율 (1순위 마이너스일때)\n단, 성장기 매출액 증가율 값이 더 작을 경우, 해당 값 적용'
                                                  },
                                                  {
                                                      title: '후기 순이익률 기본가정',
                                                      desc: '순이익률 기본가정은 다음의 조건에 따라 적용하였습니다.\n1순위) 업종평균 순이익률\n2순위) 중소기업평균 순이익률 (1순위 마이너스일때)\n단, 성장기 순이익률 값이 더 작을 경우, 해당 값 적용'
                                                  }
                                              ]}
                                              unit='%' type='number' inputWidth='30rem'/>
                                    <p className={cx('sub-label')}>({salesgrateAllList.find((it) => it.id === values.salesgrate2)?.title.split('% ')[1] ?? '직접입력'})</p>
                                </div>
                                <div className={cx('flex-input-box')}>
                                    <InputBox title='후기 순이익률 가정' name='marginrate2'
                                              inputboxCn={cx('input-box')} unit='%'
                                              type='number' inputWidth='30rem'/>
                                    <p className={cx('sub-label')}>({marginrateAllList.find((it) => it.id === values.marginrate2)?.title.split('% ')[1] ?? '직접입력'})</p>
                                </div>
                                <p className={cx('option-title')}>재무제표 정보와 상증세법상 순손익액 및 순자산가액 조정액을 입력해 주세요.</p>
                                <div className={cx('grid-box', 'box2')}>
                                    <div className={cx('inner-grid-box')}>
                                        <p/>
                                        <p>{footnote('자산평가차액', '1)')}<TooltipPop contents="1주당 순자산가치 약식 계산시 정확도를 높이기 위해 법인이 보유한 부동산 자산 등 상증세법상 자산가액과 장부가액의 차액을 반영합니다."/></p>
                                        <p>상증세법상 순자산가액</p>
                                        <p>상증세법상 순손익액</p>
                                    </div>
                                    {values.adjustInc?.map((it, i) => {
                                        const field = 'adjustInc.' + i + '.';
                                        return <div key={i} className={cx('inner-grid-box', 'box2')}>
                                            <p className={cx('year-txt')}>{years[i + 1]}년</p>
                                            {
                                                i == values.adjustInc!.length - 1 ?
                                                    <>
                                                        <NumberInputBox name={field + 'assetAdjust'} unit='원' inputWidth='14.3rem'/>
                                                        <NumberInputBox name={field + 'netAsset'} unit='원' inputWidth='14.3rem'/>
                                                    </>
                                                    :
                                                    <>
                                                        <div/>
                                                        <div/>
                                                    </>
                                            }
                                            <NumberInputBox name={field + 'netIncomeEv'} unit='원' inputWidth='14.3rem' negative={true}/>
                                        </div>
                                    })}

                                    {/*<div className={cx('inner-grid-box')}>*/}
                                    {/*    <NumberInputBox name='assetAdjust'*/}
                                    {/*                    inputboxCn={cx('input-box')}*/}
                                    {/*                    inputWidth='11.2rem'*/}
                                    {/*                    unit='원'*/}
                                    {/*                    tooltip='1주당 순자산가치 약식 계산시 정확도를 높이기 위해 법인이 보유한 부동산 자산 등 상증세법상 자산가액과 장부가액의 차액을 반영합니다.'/>*/}
                                    {/*</div>*/}
                                </div>
                                <p className={`footNote ${cx('pad-top')}`}>
                                    주1) 미래 기업가치 평가 예측시 부동산 등 자산 가치가 매년 5%씩 증가한다고 가정합니다.
                                </p>
                                <p className={cx('option-title')}>유상증자, 유상감자(이익소각 포함) 내역을 입력해 주세요.(해당 사항 있을 경우)</p>
                                <div className={cx('grid-box')}>
                                    <div className={cx('inner-grid-box')}>
                                        <SelectInputBox name='reduceCapitalType' selectBoxCn={[cx('select-box2')]}
                                                        selectList={reduceCapitalTypeList}
                                                        value={reduceCapitalTypeList[values.reduceCapitalType]?.title ?? '유상증자'}/>
                                        <p>{reduceCapitalTypeList[values.reduceCapitalType]?.title ?? '유상증자'}일</p>
                                        <p>{reduceCapitalTypeList[values.reduceCapitalType]?.title ?? '유상증자'} 주식수</p>
                                        <p>1주당 금액</p>
                                        {/*<p>자기주식 이익소각 주식수</p>*/}
                                        {/*<p>자기주식 보유 내역</p>*/}
                                    </div>



                                    {values.capInc.map((it, i) => {
                                        const field = 'capInc.' + i + '.';
                                        return <div key={i} className={cx('inner-grid-box')}>
                                            <p className={cx('year-txt')}>{years2[i]}년</p>
                                            {/*<NumberInputBox name={field + ''} unit='주' inputWidth='11.2rem'*/}
                                            {/*                negative={true}/>*/}
                                            <DateInputBox name={field + 'capincDate'} inputWidth='11.3rem'
                                                          placeholder='YYYYMMDD' maxLength={8} inputboxCn={`${capIncErr[i] && !values.capInc[i].capincDate ? 'error' : ''}`}/>
                                            <NumberInputBox name={field + 'capincShrs'} unit='주' inputWidth='11.2rem'
                                                            negative={true} inputboxCn={`${capIncErr[i] && !values.capInc[i].capincShrs ? 'error' : ''}`}/>
                                            <NumberInputBox name={field + 'issueValue'} unit='원' inputWidth='11.2rem' inputboxCn={`${capIncErr[i] && !values.capInc[i].issueValue ? 'error' : ''}`} negative={true}/>

                                            {/*<NumberInputBox name={field + ''} unit='주' inputWidth='11.2rem' negative={true}/>*/}
                                            {/*<NumberInputBox name={field + ''} unit='억원' inputWidth='11.2rem' negative={true}/>*/}
                                        </div>
                                    })}
                                </div>
                                {/*<p className={cx('info-txt')}>※ 상기 내역은 법인등기부등본 및 재무상태표 정보를 바탕으로 계산된 결과값입니다.</p>*/}
                            </div>
                        </div>
                    </div>
                </Form>;
            }}
        </Formik>
    );
};

export default CompanyValueInfo;