import styles from './payment-window-pop.module.scss';
import classNames from 'classnames/bind';
import {Field, Form, Formik, FormikValues, useFormikContext} from "formik";
import {formatNum} from "../util/str-util";
import {InputBox, SelectInputBox} from "../pages/customer/edit-common";
import {Dispatch, SetStateAction, useRef, useState} from "react";
import {useInjection} from "inversify-react";
import {GoodApi} from "../model/goods/good-api";
import {DialogManager} from "./dialog-manager";
import {usePayment} from "../model/payment/payment-hook";
import LoadingDialog from "./loading-dialog";
import {useRefreshCredit} from "../model/customer/credit-hook";
import usePromise from "../model/common/use-promise";
import {useNavigate} from 'react-router-dom';
import ChoosePayCardPop, {SelectCardType} from "./choose-pay-card-pop";
import {PaymentApi} from "../model/payment/payment-api";
import {CashPaymentInfo} from "../model/payment/dto/cash-payment-info";
import {Goods} from "../model/goods/dto/goods";
import PostCodePop from "./post-code-pop";
import {PayExtraData, PrAddressExtInfo} from "../model/payment/dto/pay-extra-data";
import * as Yup from "yup";

const cx = classNames.bind(styles);

interface PaymentWindowPopProps {
    id: string;

    onClose: VoidFunction;

    // 결제 완료
    completePayment?: (payId: string) => void;
    userVal1?: string;
    userVal2?: string;

    // 현금 결제 숨기기
    hideCash?: boolean;
}

const couponList = [
    {
        id: null,
        title: ''
    },
    {
        id: 5000,
        title: '할인쿠폰1'
    },
    {
        id: 4000,
        title: '할인쿠폰2',
    },
    {
        id: 3000,
        title: '할인쿠폰3',
    }
];

interface DiscountCouponPop {
    setOpenCouponPop: Dispatch<SetStateAction<boolean>>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const DiscountCouponPop = ({setOpenCouponPop, setFieldValue}: DiscountCouponPop) => {
    const closePop = () => {
        setFieldValue('discount', 0);
        setOpenCouponPop(false);
    };

    return (
        <div className={cx('dialog-background')}>
            <div className={`dialog-type-a ${cx('discount-coupon-pop')}`}>
                <p className='title'>할인쿠폰</p>
                <SelectInputBox
                    name='discount'
                    selectList={couponList}
                    placeholder='사용가능한 쿠폰:없음'
                    inputboxCn={cx('input-box')}
                    inputWidth='32.6rem'/>
                <div className={`btn-area ${cx('btn-area')}`}>
                    <button className='close-btn' type='button' onClick={closePop}>닫기</button>
                    <button className='main-btn' type='button' onClick={() => setOpenCouponPop(false)}>적용하기</button>
                </div>
            </div>
        </div>
    );
};

const PaymentGoodsBox = ({goods}: { goods: Goods }) => {
    const {values, setFieldValue} = useFormikContext<FormikValues>();
    const dialogManager = useInjection(DialogManager);

    const onSelectAddress = (zipCode: string, addr1: string) => {
        setFieldValue('address.addr1', addr1);
        setFieldValue('address.zipCode', zipCode);
    }

    return (
        <>
            <div className={cx('blue-box')}>
                <p className={cx('box-title')}>{goods?.name}</p>
                <div className={cx('first-box')}>
                    <p className={cx('flex-box')}><span
                        className={cx('label')}>{goods.info.priceInfo}</span><span>{formatNum({
                        num: goods?.price,
                        empty: '',
                        type: 'txt'
                    })}원</span></p>
                    {goods.info.device && <p className={cx('flex-box')}><span
                        className={cx('label')}>이용기기</span><span>{goods.info.device}</span></p>}
                </div>
                <div className={cx('second-box')}>
                    <p className={cx('flex-box')}>
                        <span className={cx('label')}>주문금액 합계</span>
                        <label className={cx('total-amt')}>
                            <Field name='total'/>{formatNum({num: values.total - values.discount!, empty: '', type: 'txt'})}원
                            {
                                goods.promotion != null && goods.promotion.firstSale > 0 && <>(첫달 {formatNum({
                                    num: goods.promotion.firstSale,
                                    empty: '',
                                    type: 'txt'
                                })}원)</>
                            }
                        </label>
                    </p>
                </div>
            </div>

            <div className={cx('input-area')}>
                <p className={cx('sub-title')}>배송지 정보</p>
                <InputBox title='받는 사람' name='address.name' inputWidth='30.6rem' inputboxCn={cx('input-box')}/>
                <InputBox title='연락처' name='address.phone' inputWidth='30.6rem' inputboxCn={cx('input-box')}/>
                {values.address.addr1 ?
                    <>
                        <InputBox title='배송지' name='address.addr1' inputWidth='30.6rem' inputboxCn={cx('input-box')}/>
                        <div className={cx('address-box')}>
                            <InputBox name='address.addr2' title=' ' inputWidth='23rem' inputboxCn={cx('input-box')} placeholder='상세주소 입력'/>
                            <button type='button' className={cx('chg-address')} onClick={() => dialogManager.open(PostCodePop, {onSelectAddress: onSelectAddress})}>변경</button>
                        </div>
                    </>
                    : <>
                        <div className={cx('add-shipping-address')}>
                            <p>배송지</p>
                            <button type='button' className={cx('add-address')} onClick={() => dialogManager.open(PostCodePop, {onSelectAddress: onSelectAddress})}><i></i>배송지 추가</button>
                        </div>
                    </>
                }

            </div>
        </>
    )
};

const PaymentDefaultBox = ({goods}: { goods: Goods }) => {
    const {values} = useFormikContext<FormikValues>();

    return (
        <div className={cx('blue-box')}>
            <p className={cx('box-title')}>{goods?.name}</p>
            <div className={cx('first-box')}>
                <p className={cx('flex-box')}><span
                    className={cx('label')}>{goods.info.priceInfo}</span><span>{formatNum({
                    num: goods?.price,
                    empty: '',
                    type: 'txt'
                })}원</span></p>
                {goods.info.device && <p className={cx('flex-box')}><span
                    className={cx('label')}>이용기기</span><span>{goods.info.device}</span></p>}

                {/*<div className={cx('flex-box')}>*/}
                {/*    <p className={cx('label')}>쿠폰적용<button className={cx('discount-coupon-btn')} onClick={() => setOpenCouponPop(true)} type='button'>할인쿠폰<i></i></button></p>*/}
                {/*    <label className={cx('discount-amt')}><Field name='discount'/>할인금액:{values.discount}원</label>*/}
                {/*</div>*/}
            </div>
            <div className={cx('second-box')}>
                <p className={cx('flex-box')}>
                    <span className={cx('label')}>주문금액 합계</span>
                    <label className={cx('total-amt')}>
                        <Field name='total'/>{formatNum({num: values.total - values.discount!, empty: '', type: 'txt'})}원
                        {
                            goods.promotion != null && goods.promotion.firstSale > 0 && <>(첫달 {formatNum({
                                num: goods.promotion.firstSale,
                                empty: '',
                                type: 'txt'
                            })}원)</>
                        }
                    </label>
                </p>
                <div className={cx('caution')}>
                    <p className={cx('caution-title')}>구매시 유의사항</p>
                    <ul>
                        {goods?.content!.note.split("\n").map((li, i) =>
                            <li key={i}>{li}</li>
                        )}
                    </ul>
                </div>

                {
                    goods?.type === "SUBSCRIPTION" &&
                    <div className={cx('flex-box')}>
                        <p className={cx('label')}>결제방법</p>
                        <div className={cx('method-area')}>
                            <label className={cx('radio-btn')}><Field name='method' type='radio' value='신용/체크카드'/>신용/체크카드</label>
                        </div>
                    </div>
                }

                {
                    goods?.act === 'ANNUAL_PASS' &&
                    <div className={cx('bottom-line')}>
                        <label className={cx('chk-box')}><Field name='confirm' type='checkbox'/>상기 이용권 환불조건을 모두 확인하였습니다.</label>
                    </div>
                }

            </div>
        </div>
    );
};

const PaymentWindowPop = ({id, onClose, userVal1, userVal2, completePayment, hideCash}: PaymentWindowPopProps) => {
    const [openCouponPop, setOpenCouponPop] = useState(false);
    const dialogManager = useInjection(DialogManager);
    const goodApi = useInjection(GoodApi);
    const navigate = useNavigate();
    const paymentApi = useInjection(PaymentApi);
    const [refreshCredit] = useRefreshCredit();

    const [startSubscription, startSinglePayment] = usePayment();
    const loadingKey = useRef(-1);
    const prAddressExt = useRef<PrAddressExtInfo>({addr1: "", addr2: "", name: "", phone: "", zipCode: ""});

    const [loading, response] = usePromise(() => id === 'REGULAR' ? goodApi.currentSubscription() : goodApi.getGoods(id), [goodApi, id]);

    if (loading || !response || !response.val) {
        return <div></div>
    }

    const goods = response.val;
    const goodName = goods?.simpleName ? goods!.simpleName : goods!.name;
    const hideCashGo = hideCash || goods.hideCash;

    const closeLoadingPop = () => {
        if (loadingKey!.current >= 0) {
            dialogManager.close(loadingKey!.current);
            loadingKey!.current = -1;
        }
    }

    const makePayExtraData = () => {
        const payExtraData:PayExtraData = {};

        if (goodMode) {
            payExtraData.addressInfo = prAddressExt.current;
        }

        payExtraData.userVal1 = userVal1;
        payExtraData.userVal2 = userVal2;

        return payExtraData;
    }

    const onPaymentComplete = (payId: string) => {
        closeLoadingPop();
        refreshCredit();

        if (goods.content?.finTitle) {
            const popContent = goods.content?.finContent ?? "결제가 완료되었습니다";

            dialogManager.alert(goods.content?.finTitle!, popContent, () => {
                if (goods.content.finLink != null) {
                    navigate(goods.content.finLink);
                }

                if (completePayment) {
                    completePayment(payId);
                }
            });
        }
        else {
            if (completePayment) {
                completePayment(payId);
            }
        }

        onClose();
    }

    const onSelectCardGo = async (cardType: SelectCardType, billingId?: string) => {
        const payExtraData = makePayExtraData();

        if (cardType === 'NORMAL') {
            startSinglePayment(goods?.id!, (goodName ?? "상품"), payExtraData, loadingPop, onPaymentComplete, onPaymentError);
        } else if (cardType === 'SIMPLE') {
            if (!billingId) return;

            loadingPop();
            const result = await paymentApi.simplePayV2(goods.id, billingId, payExtraData);

            if (result.success) {
                onPaymentComplete(result.val!.id);
            } else {
                onPaymentError(result.message, "");
            }
        }
    };

    const onSelectCashPay = async (cashPaymentInfo: CashPaymentInfo) => {
        loadingPop();

        const payExtraData = makePayExtraData();
        payExtraData.cashPaymentInfo = cashPaymentInfo;

        const result = await paymentApi.cashPaymentV2(goods.id, payExtraData);

        if (result.success) {
            closeLoadingPop();
            refreshCredit();

            dialogManager.alert("무통장 입금 신청이 완료되었습니다.", "무통장계좌정보는 모바일로 별도 전송되었습니다.", () => {
                if (goods.content.finLink != null) {
                    navigate(goods.content.finLink);
                }
            });

            if (completePayment) {
                completePayment(result.val!.id);
            }
            onClose();
        } else {
            onPaymentError(result.message, "");
        }
    }

    const startPayment = () => {
        if (goods?.type === "SUBSCRIPTION") {
            startSubscription(goods?.id!, goodName ?? "상품", loadingPop, onPaymentComplete, onPaymentError);
        }
        if (goods?.type === "SINGLE") {
            dialogManager.open(ChoosePayCardPop, {
                onSelectCard: onSelectCardGo,
                onSelectCashPay: onSelectCashPay,
                selectMode: 'PAYMENT',
                hideCash: hideCashGo
            });
        }
    }

    const onPaymentError = (message: string, errorCode: string) => {
        closeLoadingPop();
        dialogManager.alert('결제 실패', message);
    }

    const loadingPop = () => {
        loadingKey!.current = dialogManager.open(LoadingDialog, {
            aniW: '10.0rem',
            aniH: '10.0rem',
            jsonFile: 'loading',
            title: '잠시만 기다려 주세요.'
        });
    };

    const goodMode = goods != null && goods.act! === 'GOODS';

    return (
        <Formik
            initialValues={{
                discount: 0,
                method: '신용/체크카드',
                total: goods.price,
                confirm: false,
                address: goods.addressExtInfo ?? {
                    zipCode: '',
                    addr1: '',
                    addr2: '',
                    name: '',
                    phone: '',
                }
            }}
            validateOnMount={true}
            validationSchema={Yup.object({
                address: Yup.object().test('check-address', '배송지 정보를 입력해주세요', (value: any) => {
                    if (goodMode) {
                        if (value.name.length < 2) {
                            return false;
                        }

                        if (value.phone.length < 10) {
                            return false;
                        }

                        if (!value.addr1 || value.addr1.length < 1) {
                            return false;
                        }
                    }

                    return true;
                })
            })}
            onSubmit={(value) => {
                if (goods?.act === 'ANNUAL_PASS' && !value.confirm) {
                    dialogManager.alert('이용권 환불조건 동의 후\n결제 진행이 가능합니다.')
                } else {
                    if (goods?.act === 'GOODS') {
                        if (value.address.name.length < 2) {
                            dialogManager.alert("받는 사람을 입력해주세요");
                            return;
                        }

                        if (value.address.phone.length < 10) {
                            dialogManager.alert("연락처를 입력해주세요");
                            return;
                        }

                        if (value.address.addr1.length < 1) {
                            dialogManager.alert("배송지를 입력해주세요");
                            return;
                        }

                        prAddressExt.current = {
                            addr1: value.address.addr1, addr2: value.address.addr2, name: value.address.name, phone: value.address.phone, zipCode: value.address.zipCode
                        }

                        startPayment();
                    }
                    else {
                        startPayment();
                    }
                }
            }}>
            {({values, setFieldValue, isValid}) =>
                <div className='full-background'>
                    <div className={`pop-inner ${cx(goodMode ? 'purchase-materials-pop' : 'payment-window-pop')}`}>
                        <Form>
                            <p className={cx('title')}>{goodMode ? '상품 구매' : '이용권 구매'}</p>
                            {(goodMode) ? <PaymentGoodsBox goods={goods}/>: <PaymentDefaultBox goods={goods}/>}

                            <div className={cx('btn-area')}>
                                <button className={cx('close-btn')} type='button' onClick={() => {
                                    onClose()
                                }}>닫기
                                </button>
                                <button
                                    className={cx('payment-btn')}
                                    type='submit'
                                    disabled={!isValid}
                                >
                                    <i></i>결제하기
                                </button>
                            </div>
                            {openCouponPop &&
                                <DiscountCouponPop setOpenCouponPop={setOpenCouponPop} setFieldValue={setFieldValue}/>}
                        </Form>
                    </div>
                </div>}
        </Formik>
    );
};

export default PaymentWindowPop;