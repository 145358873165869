import styles from './product-box.module.scss';
import classNames from 'classnames/bind';
import {useInjection} from "inversify-react";
import {DialogManager} from "./dialog-manager";
import PaymentWindowPop from "./payment-window-pop";
import {Goods, GoodsAction} from "../model/goods/dto/goods";
import {formatNum} from "../util/str-util";

const cx = classNames.bind(styles);

function goodActToSubTitle(act?: GoodsAction) : string {
    switch (act) {
        case "ANNUAL_PASS":
            return "전문가를 위한 요금제";
        case "NORMAL_SUB":
            return "컨설턴트를 위한 요금제";
        case "MANAGE_SUB":
            return "매니저를 위한 요금제";
        case "ONE_TIME_SS":
            return "필요시에만 구매";
    }

    return "";
}

function goodActToTitle(act?: GoodsAction) : string {
    switch (act) {
        case "ANNUAL_PASS":
            return "스마트 연간 이용권";
        case "NORMAL_SUB":
        case "MANAGE_SUB":
            return "스마트 구독형";
        case "ONE_TIME_SS":
            return "1회 이용권";
    }

    return "";
}

const ProductBox = ({goods, onClose}: {goods: Goods, onClose:VoidFunction}) => {
    const dialogManager = useInjection(DialogManager);

    const subTitle = goods.type === "SUBSCRIPTION" ? "월 정기구독 상품" : (goods.act === "ANNUAL_PASS" ? "연간 멤버십 상품" : "단건 구매 상품");

    return(
        <div className={cx('product-box')}>
            <p className={cx('sub-title')}><i className={cx('icon', goods.content.icon)}></i>{goodActToSubTitle(goods.act)}</p>
            <p className={cx('title')}><span>{goodActToTitle(goods.act)}</span><span className={cx('type')}>({subTitle})</span></p>

            <p className={cx('desc')}>
                {goods.type === "SUBSCRIPTION" && "미사용 이용권은 이월되지 않음"}
                {goods.act === "ONE_TIME_SS" && <>
                    이용권 구매일로부터 <span>+{goods.meta.val3}일간</span> 이용 가능
                </>}
                {goods.act === "ANNUAL_PASS" && <>
                    이용권 구매일로부터 <span>+1년간</span> 이용 가능
                </>}
            </p>

            <button className={cx('payment-btn')} onClick={() => {
                onClose();
                dialogManager.open(PaymentWindowPop, {id: goods.id});
            }}>

                <p className={cx('credit')}><span>{goods.meta.val2 ?? ""}건</span> <span className={cx('month')}>
                {goods.type === "SUBSCRIPTION" && "/ 월"}
                {goods.act === "ANNUAL_PASS" && "/ 년"}

                </span></p>
                <p className={cx('price')}>
                    ₩ {formatNum({num: goods.finalPrice, empty: '', type: 'txt'})}<i className={cx('arrow')}></i></p>
            </button>
        </div>
    );
}

export default ProductBox;