import {Field, Form, Formik, FormikValues, useFormikContext} from 'formik';
import * as Yup from 'yup';
import styles from './salary-info.module.scss';
import classNames from 'classnames/bind';
import {DateInputBox, InputBox, NumberInputBox, SelectInputBoxProps} from './customer/edit-common';
import React, {useEffect, useState} from 'react';
import {ReportAddInfoPopProps} from './report-add-info-pop';
import {asyncCall} from '../util/util';
import {toast} from 'react-toastify';
import {strSaved} from '../data/strings';
import {useInjection} from 'inversify-react';
import {useMountedState} from 'react-use';
import {SelectItem} from '../model/types';
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";
import {DialogManager} from "../components/dialog-manager";
import {formatNum} from "../util/str-util";
import {CustomerApi} from "../model/customer/customer-api";
import {PrCustomerRawData} from "../model/customer/dto/customer-raw-data";
import usePromise from "../model/common/use-promise";
import {CoReportDto} from "../model/co-report/dto/co-report-dto";
import SelectButton from "../components/select-button";
import { MngSalary } from '../model/co-report/dto/mng-salary';
import _ from 'lodash';

const cx = classNames.bind(styles);

interface MultipleOptionProps {
    title?: string;
    name?: string;
    name2?: string;
    firstBoxValue?: string;
    secondBoxValue?: string;
    selectBoxList?: string[] | SelectItem[];
    openAbove?: boolean;
    selectSalaryMngIdx: number;
}

const EspSelectInputBox = ({
  name,
  value,
  selectList,
  openAbove = false,
  intIdx,
  onChange,
  ...props
}: SelectInputBoxProps) => {
  return (
    <>
        {
            <InputBox name={name} {...props}>
                <input className='input-txt' type='text' value={value ?? ''} readOnly/>
            </InputBox>   
        }
    </>
  );
};

const MultipleOption = ({
                            title,
                            name,
                            name2,
                            firstBoxValue,
                            secondBoxValue,
                            selectBoxList,
                            openAbove,
                            selectSalaryMngIdx
                        }: MultipleOptionProps) => {

            let list = selectBoxList ? selectBoxList : [];
            const tempList = list.map((it, i) => ({id: i, title: it as string}));

            const {values, setFieldValue} = useFormikContext<FormikValues>();
            const [selected, setSelected] = useState(0);

            /**
             * 퇴직금 지급배수 select 의 실제 저장하기 위한 값과 별도로 UI 에 이름만 출력하기 위해 만든 변수
             * 해당 변수는 DB 에 값 저장 X
             */
            /*********** */
            const nameSplitList = name2?.split(".") ?? "";
            const espName = nameSplitList !== "" ? nameSplitList[3] : "";
            const espValue = values.info.execSeverancePayList[selectSalaryMngIdx][espName] ?? "";
            const inputName = 'info.espNameList.' + espName + "." + selectSalaryMngIdx + ".";
            /*********** */

            useEffect(() => {
                /**
                 * 화면 처음 로드 시, 백엔드에는 퇴직금 지급배수 이름만 저장한 변수가 없기에 초기값 세팅
                 */
                if(list.length !== 0) {
                    setFieldValue(inputName + "name", list[espValue]);
                }
            }, [selectSalaryMngIdx])

    return (
        <div className={cx('multiple-option')}>
            {
                title ?
                    <InputBox
                        title={title}
                        unit='배'
                        name={name}
                        type='number'
                        value={firstBoxValue ?? undefined}
                        inputboxCn={cx('first-input-box')}
                        inputWidth='11.2rem'/>
                    :
                    <></>
            }
            {/* <SelectInputBox
                name={name2 ?? undefined}
                selectList={selectBoxList ?? undefined}
                value={secondBoxValue ?? undefined}
                openAbove={openAbove}
                intIdx={true}
                inputWidth='26.9rem'/> */}

            {
                list.length !== 0 ? 
                <SelectButton
                    cn={[cx('select-btn')]}
                    data={tempList}
                    onSelect={id => {
                        setFieldValue(inputName + "name", list[id]);
                        setFieldValue(name2 ?? "", id);
                        setSelected(id);
                        
                    }}
                    containerStyle={{display: "block"}}
                    listWidth='26.9rem'
                >
                    <InputBox
                        title={``}
                        name={inputName + "name"}
                        placeholder='선택'
                        inputboxCn={cx('input-box', 'esp-box', 'input-box-color', 'select-box')}
                        inputWidth='26.9rem'
                        readOnly />
                </SelectButton>
                :
                <EspSelectInputBox 
                    name={name2 ?? undefined}
                    selectList={list}
                    value={secondBoxValue ?? undefined}
                    openAbove={openAbove}
                    intIdx={true}
                    inputWidth='26.9rem'
                />
            }
        </div>
    );
};

const InfoByMember = ({data, selectSalaryMngIdx, initMngIdx}: {data: CoReportDto, selectSalaryMngIdx: number, initMngIdx: number}) => {
    const customer = data.customer;
    const mng = _.cloneDeep(data.customer.mng.list);
    const allMng = _.cloneDeep(data.customer.salary.allMng);

    const {values, setFieldValue} = useFormikContext<FormikValues>();
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        setSelected(initMngIdx + 1);
    }, [selectSalaryMngIdx]);
    
    const mngList = data.customer.mng.list.map((it, i) => ({id: i + 1, title: it.NAME}));
    mngList.unshift({id: 0, title: "선택"});

    const radioOptions = ['전체 근속기간 동일 지급배수', '임원퇴직금지급규정 상 근속기간별 지급배수', '퇴직소득한도 세법규정과 일치'];
    const incomeBase_2019_list = ['퇴직일 소급 3년 연평균급여', '2019년 소급 3년 연평균급여'];
    const incomeBase_2011_list = ['퇴직시점 연평균 급여', '2011년 연평균급여'];
    const avgIncomeOption_list = ['연평균급여의 1/10', '월평균급여'];

    const field = 'info.mng.' + selectSalaryMngIdx + '.';
    const espField = 'info.execSeverancePayList.' + selectSalaryMngIdx + '.';
    const rawField = 'info.salaryRawList.' + selectSalaryMngIdx;

    const footnote = (title: string, footnote: string) => <>{title}<span
        className='numbering'>{footnote ?? ''}</span></>;

    return(
        <div className={cx('input-area')}>
            <p className={cx('sub-title')}>1. 임원 급여 정보를 입력해 주세요.</p>
            <SelectButton
                cn={[cx('select-btn')]}
                data={mngList}
                onSelect={id => {
                    setFieldValue('info.mngIdx', id);
                    setSelected(id);

                    let mngSalary: MngSalary = {
                        no: "",
                        birth: "",
                        name: "선택",
                        ttl: "",
                        pcd: "",
                        income: 0,
                        income_2011: 0,
                        income_2019: 0,
                        joinDate: "",
                        retireDate: "",
                    };

                    if(id !== 0) {
                        const name = mngList[id].title;

                        allMng.some((mng, idx) => {
                            if(mng.name === name) {
                                mngSalary = _.cloneDeep(mng);
                                return true;
                            }

                            return false;
                        })

                        // mngSalary = _.cloneDeep(allMng[id - 1]);
                    }

                    customer.salary.mng[selectSalaryMngIdx] = mngSalary;
                    setFieldValue('info.mng.' + selectSalaryMngIdx, mngSalary);
                }}
                containerStyle={{display: "block"}}
                listWidth='52rem'>
                <InputBox
                    title={`임원명`}
                    name={field + 'name'}
                    placeholder='선택'
                    inputboxCn={cx('input-box', 'input-box-color', 'select-box')}
                    inputWidth='52rem'
                    readOnly />
            </SelectButton>
            <InputBox title='직위' name={field + 'ttl'} inputboxCn={cx('input-box', 'input-box-color')} inputWidth='52rem' readOnly />
            <InputBox title='생년월일' name={field + 'birth'} inputboxCn={cx('input-box')} inputWidth='52rem' />
            <DateInputBox title={footnote('취임일자', '1)')} name={field + 'joinDate'} inputboxCn={cx('input-box')} inputWidth='52rem' maxLength={8}/>
            <DateInputBox title={footnote('예상퇴직일자', '2)')} name={field + 'retireDate'} inputboxCn={cx('input-box')} maxLength={8} inputWidth='52rem'/>
            <NumberInputBox title={footnote('현재 연평균급여', '3)')} name={field + 'income'}
                            inputboxCn={cx('input-box')}
                            unit='원'
                            inputWidth='52rem'/>
            <NumberInputBox title='2017~2019년 연평균급여' name={field + 'income_2019'}
                            inputboxCn={cx('input-box')}
                            unit='원'
                            inputWidth='52rem'/>

            {customer?.companyInfo.estbDt < '20120101' &&
                <NumberInputBox title='2011년 연평균급여' name={field + 'income_2011'}
                                inputboxCn={cx('input-box')}
                                unit='원'
                                inputWidth='52rem'/>}
            <p className='footNote'>
                주1) 등기부등본의 대표이사 및 사내이사 취임일 정보 중 더 빠른 날짜를 적용합니다.<br/>
                주2) 대표이사 나이가 50세 미만일 경우 60세 은퇴를 가정하였으며, 50세 이상일 경우 10년 뒤 은퇴를 가정하여 자동 계산된 값입니다.<br/>
                주3) 현재 연평균급여가 퇴직시점까지 동일하게 유지된다고 가정합니다.
            </p>
            <p className={cx('sub-title', 'title2')}>2. 임원퇴직금지급규정(또는 신규 제정) 정보를 입력해 주세요.</p>
            <div className={cx('severance-pay-info')}>
                <p className={cx('option-title')}>퇴직금 지급배수</p>
                <ul role='group' aria-labelledby='my-radio-group'>
                    <li>
                        <label className={cx('radio-btn')}>
                            <Field
                                type='radio'
                                name={espField + 'multipleOption'}
                                value='0'/>
                            {radioOptions[0]}
                        </label>
                        {values.info.execSeverancePayList[selectSalaryMngIdx].multipleOption === '0' &&
                            <MultipleOption title='전체 근속기간' name={espField + 'multiple'}
                                            secondBoxValue='퇴직일 소급 3년 연평균급여'
                                            selectSalaryMngIdx={selectSalaryMngIdx} />}
                    </li>
                    <li>
                        <label className={cx('radio-btn')}>
                            <Field
                                type='radio'
                                name={espField + 'multipleOption'}
                                value='1'/>
                            {radioOptions[1]}
                        </label>
                        {values.info.execSeverancePayList[selectSalaryMngIdx].multipleOption === '1' &&
                            <>
                                <MultipleOption title='2020년 이후' name={espField + 'multiple_2020'}
                                                secondBoxValue='퇴직일 소급 3년 연평균급여'
                                                selectSalaryMngIdx={selectSalaryMngIdx} />
                                <MultipleOption title='2019년 이전' name={espField + 'multiple_2019'}
                                                name2={espField + 'incomeBase_2019'}
                                                selectBoxList={incomeBase_2019_list}
                                                selectSalaryMngIdx={selectSalaryMngIdx} />
                                {customer?.companyInfo.estbDt < '20120101' &&
                                    <label className={cx('check-box')}>
                                        <Field
                                            type='checkbox'
                                            name={espField + 'retireDoc_2011'}/>
                                        2011년 이전 제정한 임원퇴직금지급규정 있음
                                    </label>}
                                {values.info.execSeverancePayList[selectSalaryMngIdx].retireDoc_2011 &&
                                    <>
                                        <MultipleOption title='2011년 이전' name={espField + 'multiple_2011'}
                                                        name2={espField + 'incomeBase_2011'}
                                                        selectBoxList={incomeBase_2011_list}
                                                        selectSalaryMngIdx={selectSalaryMngIdx} />
                                        <MultipleOption name2={espField + 'avgIncomeOption'}
                                                        openAbove={true}
                                                        selectBoxList={avgIncomeOption_list}
                                                        selectSalaryMngIdx={selectSalaryMngIdx} />
                                    </>}
                            </>
                        }
                    </li>
                    <li>
                        <label className={cx('radio-btn')}>
                            <Field
                                type='radio'
                                disabled
                                checked/>
                            {radioOptions[2]}
                        </label>
                        <>
                            <MultipleOption title='2020년 이후' firstBoxValue='2'
                                            secondBoxValue='퇴직일 소급 3년 연평균급여'
                                            selectSalaryMngIdx={selectSalaryMngIdx} />
                            <MultipleOption title='2019년 이전' firstBoxValue='3'
                                            secondBoxValue='2019년 소급 3년 연평균급여'
                                            selectSalaryMngIdx={selectSalaryMngIdx} />
                        </>
                    </li>
                </ul>
            </div>
            {
                (selected - 1) > -1 && mng && mng.length > 0 && mng[selected - 1].NO.startsWith("C") ?
                <>
                    <p className={cx('sub-title', 'title2')}>3. CEO 적정 급여 및 배당 설계 가정 금액을 입력해 주세요.</p>
                    <div className={cx('table')}>
                        <div className={cx('head')}>
                            <p>급여 단독 설계 가정</p>
                            <p>배당 + 급여 혼합설계 가정</p>
                        </div>
                        <div className={cx('body')}>
                            {values.raw.salary.map((salary: number, i: number) => {
                                const dividend = values.raw.dividend[i];
                                    return <div className={cx('flex-body')} key={i}>
                                        <NumberInputBox title={`연간급여 ${i + 1}`} name={`raw.salary.${i}`} unit='원'
                                                inputWidth='14.1rem'
                                                inputboxCn={cx('table-input-box')}/>
                                        <div className={cx('mix')}>
                                            <NumberInputBox
                                                title={`연간배당 ${i + 1}`}
                                                name={`raw.dividend.${i}`}
                                                unit='원' inputWidth='14.1rem'
                                                inputboxCn={cx('table-input-box')}
                                            />
                                            <span className={cx('plus')}>+</span>
                                            <span className={cx('salary')}>{formatNum({num: salary - dividend})}원</span>
                                        </div>
                                    </div>;
                                }
                            )}
                        </div>
                    </div> 
                </>
                : null
            }
        </div>
    );
}

const SalaryInfo = ({data, setData, onClose}: ReportAddInfoPopProps) => {
    const customerApi = useInjection(CustomerApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const isMounted = useMountedState();
    const dialogManager = useInjection(DialogManager);
    // const [selected, setSelected] = useState(data.customer?.salary.repIdx);
    const {clearInfo} = useCustomerInputClear(setData);
    const [selectIdx, setSelectIdx] = useState(0);

    const [loading, rowDataResult] = usePromise(() => customerApi.rawData(data.customer.id), [data]);

    if (loading || !rowDataResult) return <div></div>;

    const customer = data.customer;
    const rawData: PrCustomerRawData = rowDataResult?.val!;

    if (!customer) return null;

    const getInitMngIdx = (idx: number) => {
        let initIdx = 0;

        try {
            const salaryMng = data.customer.salary.mng[idx];
            const mng = data.customer.mng.list;
            
            mng.map((it, i) => {
                if(it.NO === salaryMng.no) {
                    initIdx = i;
                    throw initIdx;
                }
            });
        } catch (e) {
            
        }

        return initIdx;
    }

    const dateValidCheck = (date: string, dateType: string, name: string) => {
        if(date.length > 8 || date.length < 8) {
            dialogManager.alert(`${name}님의 ${dateType} 날짜가 유효하지 않습니다.`);
            return false;
        } else {
            const dateCheck = new Date(date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8));

            if(dateCheck.toString() === "Invalid Date") {
                dialogManager.alert(`${name}님의 ${dateType} 날짜가 유효하지 않습니다.`);
                return false;
            }
        }

        return true;
    }

    // const rep = customer.salary.rep;

    // const repList = rep.map((it, i) => ({id: i, title: it.name}));
    //
    // const radioOptions = ['전체 근속기간 동일 지급배수', '임원퇴직금지급규정 상 근속기간별 지급배수', '퇴직소득한도 세법규정과 일치'];
    // const incomeBase_2019_list = ['퇴직일 소급 3년 연평균급여', '2019년 소급 3년 연평균급여'];
    // const incomeBase_2011_list = ['퇴직시점 연평균 급여', '2011년 연평균급여'];
    // const avgIncomeOption_list = ['연평균급여의 1/10', '월평균급여'];
    //
    // const field = 'info.rep.' + selected + '.';

    return (
        <Formik
            initialValues={{info: customer.salary, raw: rawData.salaryRaw}}
            validationSchema={
                Yup.object({
                    info: Yup.object({
                        rep: Yup.array().of(
                            Yup.object({
                                joinDate: Yup.string().min(8, '8자로 입력해 주세요.').matches(/^[0-9]+$/, '숫자만 입력해 주세요.'),
                                retireDate: Yup.string().min(8, '8자로 입력해 주세요.').matches(/^[0-9]+$/, '숫자만 입력해 주세요.'),
                            })
                        )
                    })
                })
            }
            onSubmit={(values) => {
                let dateValidResult = true;

                values.info.mng.some((value, idx) => {
                    const birthCheck = dateValidCheck(value.birth, "생년월일", value.name);
                    const joinCheck = dateValidCheck(value.joinDate, "취임일자", value.name);
                    const retireCheck = dateValidCheck(value.retireDate, "예상퇴직일자", value.name);

                    if(!birthCheck || !joinCheck || !retireCheck) {
                        dateValidResult = false;
                        return;
                    }
                });

                if(dateValidResult) {
                    asyncCall(customerInputApi.saveSalaryInfo, [customer.id, values], result => {
                        result.customer.salary.mngIdx = selectIdx;
                        setData(result);
                        toast.success(strSaved);
                    }, isMounted, (e) => {
                        dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                    });
                }
            }}
        >
            {({values, setFieldValue, resetForm}) =>
                <Form className={cx('salary-info')}>
                    <div className={cx('btn-area')}>
                        <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                        <button className='close-btn' type='button' onClick={() => onClose()}>닫기</button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <p className={cx('title')}>임원 급여 및 퇴직금 정보<button className={cx('reset-btn')} type='button' onClick={() => clearInfo(customer.id, 'salary',
                                    (report, rawData) => resetForm({values: {info: report.customer.salary, raw: rawData?.salaryRaw!}})
                                    )}><i></i>초기화</button></p>
                            <div className={cx('input-area')}>
                                <div className={cx('tab-area')}>
                                    <button type='button' className={cx(selectIdx === 0 && 'on')} onClick={() => setSelectIdx(0)}>임원 1
                                    </button>
                                    <button type='button' className={cx(selectIdx === 1 && 'on')} onClick={() => setSelectIdx(1)}>임원 2
                                    </button>
                                    <button type='button' className={cx(selectIdx === 2 && 'on')} onClick={() => setSelectIdx(2)}>임원 3
                                    </button>
                                </div>
                                <InfoByMember data={data} selectSalaryMngIdx={selectIdx} initMngIdx={getInitMngIdx(selectIdx)} />
                                {/*<p className={cx('sub-title')}>1. 임원 급여 정보를 입력해 주세요.</p>*/}
                                {/*<InputBox title='임원명'*/}
                                {/*          inputboxCn={cx('input-box')}*/}
                                {/*          inputWidth='52rem'>*/}
                                {/*    <SelectBox selectList={repList}*/}
                                {/*               selected={selected}*/}
                                {/*               selectBoxCn={['gray-box']} inputBoxCn='font14'*/}
                                {/*               width='100%'*/}
                                {/*               placeholder='선택'*/}
                                {/*               onChange={id => {*/}
                                {/*                   setFieldValue('info.repIdx', id);*/}
                                {/*                   setSelected(id);*/}
                                {/*               }}*/}
                                {/*    />*/}
                                {/*</InputBox>*/}
                                {/*<InputBox title='직위' name={field + 'position'} inputboxCn={cx('input-box')} inputWidth='52rem'/>*/}
                                {/*<InputBox title='현재나이' name={field + 'age'} inputboxCn={cx('input-box')} inputWidth='52rem'/>*/}
                                {/*<DateInputBox title={footnote('취임일자', '1)')} name={field + 'joinDate'} inputboxCn={cx('input-box')} inputWidth='52rem' maxLength={8}/>*/}
                                {/*<DateInputBox title={footnote('예상퇴직일자', '2)')} name={field + 'retireDate'} inputboxCn={cx('input-box')} maxLength={8} inputWidth='52rem'/>*/}
                                {/*<NumberInputBox title={footnote('현재 연평균급여', '3)')} name={field + 'income'}*/}
                                {/*                inputboxCn={cx('input-box')}*/}
                                {/*                unit='원'*/}
                                {/*                inputWidth='52rem'/>*/}
                                {/*<NumberInputBox title='2017~2019년 연평균급여' name={field + 'income_2019'}*/}
                                {/*                inputboxCn={cx('input-box')}*/}
                                {/*                unit='원'*/}
                                {/*                inputWidth='52rem'/>*/}

                                {/*{customer?.companyInfo.estbDt < '20120101' &&*/}
                                {/*    <NumberInputBox title='2011년 연평균급여' name={field + 'income_2011'}*/}
                                {/*                    inputboxCn={cx('input-box')}*/}
                                {/*                    unit='원'*/}
                                {/*                    inputWidth='52rem'/>}*/}
                                {/*<p className='footNote'>*/}
                                {/*    주1) 등기부등본의 대표이사 및 사내이사 취임일 정보 중 더 빠른 날짜를 적용합니다.<br/>*/}
                                {/*    주2) 대표이사 나이가 50세 미만일 경우 60세 은퇴를 가정하였으며, 50세 이상일 경우 10년 뒤 은퇴를 가정하여 자동 계산된 값입니다.<br/>*/}
                                {/*    주3) 현재 연평균급여가 퇴직시점까지 동일하게 유지된다고 가정합니다.*/}
                                {/*</p>*/}
                                {/*<p className={cx('sub-title', 'title2')}>2. 임원퇴직금지급규정(또는 신규 제정) 정보를 입력해 주세요.</p>*/}
                                {/*<div className={cx('severance-pay-info')}>*/}
                                {/*    <p className={cx('option-title')}>퇴직금 지급배수</p>*/}
                                {/*    <ul role='group' aria-labelledby='my-radio-group'>*/}
                                {/*        <li>*/}
                                {/*            <label className={cx('radio-btn')}>*/}
                                {/*                <Field*/}
                                {/*                    type='radio'*/}
                                {/*                    name='info.multipleOption'*/}
                                {/*                    value='0'/>*/}
                                {/*                {radioOptions[0]}*/}
                                {/*            </label>*/}
                                {/*            {values.info.multipleOption === '0' &&*/}
                                {/*                <MultipleOption title='전체 근속기간' name='info.multiple'*/}
                                {/*                                secondBoxValue='퇴직일 소급 3년 연평균급여'/>}*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <label className={cx('radio-btn')}>*/}
                                {/*                <Field*/}
                                {/*                    type='radio'*/}
                                {/*                    name='info.multipleOption'*/}
                                {/*                    value='1'/>*/}
                                {/*                {radioOptions[1]}*/}
                                {/*            </label>*/}
                                {/*            {values.info.multipleOption === '1' &&*/}
                                {/*                <>*/}
                                {/*                    <MultipleOption title='2020년 이후' name='info.multiple_2020'*/}
                                {/*                                    secondBoxValue='퇴직일 소급 3년 연평균급여'/>*/}
                                {/*                    <MultipleOption title='2019년 이전' name='info.multiple_2019'*/}
                                {/*                                    name2='info.incomeBase_2019'*/}
                                {/*                                    selectBoxList={incomeBase_2019_list}/>*/}
                                {/*                    {customer?.companyInfo.estbDt < '20120101' &&*/}
                                {/*                    <label className={cx('check-box')}>*/}
                                {/*                        <Field*/}
                                {/*                            type='checkbox'*/}
                                {/*                            name='info.retireDoc_2011'/>*/}
                                {/*                        2011년 이전 제정한 임원퇴직금지급규정 있음*/}
                                {/*                    </label>}*/}
                                {/*                    {values.info.retireDoc_2011 &&*/}
                                {/*                        <>*/}
                                {/*                            <MultipleOption title='2011년 이전' name='info.multiple_2011'*/}
                                {/*                                            name2='info.incomeBase_2011'*/}
                                {/*                                            selectBoxList={incomeBase_2011_list}/>*/}
                                {/*                            <MultipleOption name2='info.avgIncomeOption'*/}
                                {/*                                            openAbove={true}*/}
                                {/*                                            selectBoxList={avgIncomeOption_list}/>*/}
                                {/*                        </>}*/}
                                {/*                </>*/}
                                {/*            }*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <label className={cx('radio-btn')}>*/}
                                {/*                <Field*/}
                                {/*                    type='radio'*/}
                                {/*                    disabled*/}
                                {/*                    checked/>*/}
                                {/*                {radioOptions[2]}*/}
                                {/*            </label>*/}
                                {/*            <>*/}
                                {/*                <MultipleOption title='2020년 이후' firstBoxValue='2'*/}
                                {/*                                secondBoxValue='퇴직일 소급 3년 연평균급여'/>*/}
                                {/*                <MultipleOption title='2019년 이전' firstBoxValue='3'*/}
                                {/*                                secondBoxValue='2019년 소급 3년 연평균급여'/>*/}
                                {/*            </>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                
                                {/* {
                                    customer.salary.mng[selectIdx].no == "C0" ?
                                    <>
                                        <p className={cx('sub-title', 'title2')}>3. CEO 적정 급여 및 배당 설계 가정 금액을 입력해 주세요.</p>
                                        <div className={cx('table')}>
                                            <div className={cx('head')}>
                                                <p>급여 단독 설계 가정</p>
                                                <p>배당 + 급여 혼합설계 가정</p>
                                            </div>
                                            <div className={cx('body')}>
                                                {values.raw.salary.map((salary: number, i: number) => {
                                                    const dividend = values.raw.dividend[i];
                                                        return <div className={cx('flex-body')} key={i}>
                                                            <NumberInputBox title={`연간급여 ${i + 1}`} name={`raw.salary.${i}`} unit='원'
                                                                    inputWidth='14.1rem'
                                                                    inputboxCn={cx('table-input-box')}/>
                                                            <div className={cx('mix')}>
                                                                <NumberInputBox
                                                                    title={`연간배당 ${i + 1}`}
                                                                    name={`raw.dividend.${i}`}
                                                                    unit='원' inputWidth='14.1rem'
                                                                    inputboxCn={cx('table-input-box')}
                                                                />
                                                                <span className={cx('plus')}>+</span>
                                                                <span className={cx('salary')}>{formatNum({num: salary - dividend})}원</span>
                                                            </div>
                                                        </div>;
                                                    }
                                                )}
                                            </div>
                                        </div> 
                                    </>
                                    : null
                                } */}
                                
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    );
};

export default SalaryInfo;