import {useInjection} from 'inversify-react';
import {AuthStore} from '../../model/auth/auth-store';
import React, {useEffect, useState} from 'react';
import IntroBox from '../../components/intro-box';
import styles from './login.module.scss';
import classNames from 'classnames/bind';
import {Link, useParams} from 'react-router-dom';
import {Form, Formik} from 'formik';
import {useMountedState} from 'react-use';
import {asyncCall} from '../../util/util';
import {DialogManager} from '../../components/dialog-manager';
import InputAuthNum from './input-auth-num';
import {toast} from 'react-toastify';
import PrimaryButton from '../../components/primary-button';
import FormikField from '../../components/formik-field';
import {strError} from '../../data/strings';
import {MemberApi} from '../../model/intro/member-api';

const cx = classNames.bind(styles);

export const Login = () => {
    const params = useParams();
    const loginType = params.type;
    const isMounted = useMountedState();
    const authStore = useInjection(AuthStore);
    const memberApi = useInjection(MemberApi);
    const dialogManager = useInjection(DialogManager);
    const [type, setType] = useState<string | null>("normal");

    const onResult = (token: string) => {
        asyncCall(authStore.loginReqCert, [token], seq => {
            dialogManager.open(InputAuthNum, {token: token, seq: seq});
        }, isMounted, () => {
            toast.success(strError);
        });
    }

    useEffect(() => {
        if(loginType) {
            setType(loginType);
        }
    }, [loginType]);

    return <IntroBox>
        <p className='page-title'>로그인</p>
        {loginType && <div className={cx('info')}><i></i>씨오파트너 세무사 회원은 기존 계정으로 사용이 가능합니다.</div>}
        <Formik initialValues={{username: authStore.lastLoginId(), password: ''}}
                onSubmit={async value => {
                    if(value.username.search(/[^a-zA-Z\d]/) >= 0) {
                        dialogManager.alert('아이디는 영문 소문자, 숫자만 입력 가능합니다.');
                        return;
                    }

                    try {
                        if(isMounted()) {
                            const existsId = await memberApi.checkUsername(value.username);
                            if(!existsId.val) {
                                dialogManager.alert('존재하지 않는 아이디입니다.');
                                return;
                            } else {
                                const result = await authStore.login(value.username, value.password, authStore.getDeviceId());
                                if(result.success && result.val) {
                                    if (result.val.mt) onResult(result.val.mt);
                                    else if (result.val.fail != null) {
                                        if (result.val.fail === -1) dialogManager.alert('비밀번호 오류 횟수가 초과되어\n계정이 잠금되었습니다.\n' + '고객센터로 문의해 주세요.');
                                        else dialogManager.alert('비밀번호가 틀렸습니다. 5회 이상 잘못 입력할 경우 계정이 잠기게 되니 주의해 주세요.\n(' + result.val.fail + '회/5회 오류)');
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e);
                        dialogManager.alert(strError);
                    }
                }}
                // validationSchema={signInSchema}
                >
            {({values, handleChange, errors, touched, setFieldError, setFieldTouched}) =>
                <Form className={cx('login-form')}>
                    <div className='label-box'>
                        <label>아이디</label>
                        <div className={`input-box ${cx('input-box')}`}>
                            <FormikField name='username'
                                className={cx(touched.username && errors.username && 'error')}
                                placeholder='아이디'
                                type='text'
                                autoCorrect='off' autoCapitalize='none'
                                maxLength={12}
                                autoFocus
                                />
                        </div>
                    </div>
                    <div className='label-box'>
                        <label>비밀번호</label>
                        <div className={`input-box last ${cx('input-box')}`}>
                            <FormikField name='password'
                                        className={cx(touched.password && errors.password && 'error')}
                                        placeholder='비밀번호'
                                        type='password'
                                        maxLength={20}/>
                        </div>
                    </div>
                    <PrimaryButton type='submit' disabled={!(values.username.length > 4 && values.password.length >= 8)}>로그인</PrimaryButton>
                </Form>}
        </Formik>
        <div className={cx('find-and-join')}>
            <div className={cx('find-area')}>
                <Link to='/find-id'>아이디 찾기</Link>
                <Link to='/find-pw'>비밀번호 찾기</Link>
            </div>
            <p className='question'>계정이 없으신가요? <Link to={`/join/${type}`} className='answer'>회원가입</Link></p>
        </div>
    </IntroBox>
}