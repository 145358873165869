import {action, makeObservable, observable, runInAction} from "mobx";
import {KatalkApi} from "../model/katalk/katalk-api";
import container from "../model/bootstrap-container";
import {KktAutoFriendDto} from "../model/katalk/dto/kkt-auto-friend-dto";
import {KktAutoGroupDto} from "../model/katalk/dto/kkt-auto-group-dto";
import {KktAutoFriendForm} from "../model/katalk/form/kkt-auto-friend-form";
import { DialogManager } from "../components/dialog-manager";
import KktAddFailureListPop from "../pages/katalk/components/kkt-add-failure-list-pop";
import { CallKktAutoApi } from "../model/katalk/call-kkt-auto-api";
import { hasNewVersion } from "../util/str-util";
import { KktSendMsgDto } from "../model/katalk/dto/kkt-send-msg-dto";

export class KatalkController {
    private _katalkApi: KatalkApi;
    private _callKktAutoApi: CallKktAutoApi;
    private _dialogManager: DialogManager;
    friendsList:KktAutoFriendDto[] = [];
    sendFriends:KktAutoFriendDto[] = [];
    noSendFriends:KktAutoFriendDto[] = [];
    groupList: KktAutoGroupDto[] = [];
    dupList: KktAutoFriendForm[] = [];
    sendFriendsSearchList: KktAutoFriendDto[] | undefined = undefined;
    selectedGroup: string | undefined = undefined;
    readonly latestVersion: string = '1.3.0';
    lastState: string = '';
    isStopProcess: boolean = false;
    checkStateId: NodeJS.Timeout = setTimeout(() => {});
    recvListSelectedGroup: string[] = [];   // 카카오톡발송 설정 페이지에서 선택한 그룹의 Ids
    processState: 'NONE'| 'PROCESS' | 'COMPLETE' = 'NONE';

    /**
     * 카카오톡 친구를 가져올 방법 구분 값
     * 1: 현재 열려있는 채팅방에서 가져오기
     * 2: 친구 이름으로 검색해서 가져오기
     * 3: n번째 친구부터 m명 가져오기
     */
    kktSearchCase: string = '1';
    sendRange: number = 50;
    sendMsgList: KktSendMsgDto[] = [];
    sendMsgRangeIdx: number = 0;  // 카카오톡 발송 시 50명 이상일 경우, sendRange 값만큼 나눈 인덱스
    currSendMsgIdx: number = 0;  // 현재 전송중인 카카오톡 발송 리스트 인덱스
    imgName: string = "";
    imgData: string = "";
    successCnt: number = 0;
    failedCnt: number = 0;
    failedList: string[] = [];
    remainMsg: boolean = false;

    constructor() {
        this._katalkApi = container.get(KatalkApi);
        this._callKktAutoApi = container.get(CallKktAutoApi);
        this._dialogManager = container.get(DialogManager);

        makeObservable(
            this, {
                friendsList: observable,
                groupList: observable,
                sendFriends: observable,
                noSendFriends: observable,
                dupList: observable,
                sendFriendsSearchList: observable,
                selectedGroup: observable,
                lastState: observable,
                isStopProcess: observable,
                checkStateId: observable,
                recvListSelectedGroup: observable,
                processState: observable,
                updateFriends: action,
                updateGroup: action
            }
        )
    }

    updateFriends = async () => {
        this.friendsList = await this._katalkApi.getAllList();

        runInAction(() => {
            this.updateSendableFriends();
        })
    }

    updateSendableFriends = () => {
        this.sendFriendsSearchList = undefined;
        this.sendFriends = this.friendsList.filter((li) => li.sendable);
        this.noSendFriends = this.friendsList.filter((li) => !li.sendable);
    }

    updateGroupMember = () => {
        this.sendFriendsSearchList = this.sendFriends!.filter((fi) => fi.groupId === this.selectedGroup);
    }

    updateGroup = async () => {
        this.groupList = await this._katalkApi.getGroupList();
    }

    addFriends = async (data: KktAutoFriendForm[]) => {
        await this._katalkApi.addFriends(data);

        runInAction(() => {
            this.updateFriends();
        })
    }

    removeList = async (friendIds: string[]) => {
        this.sendFriendsSearchList = undefined;
        const result = await this._katalkApi.outSendList(friendIds);

        runInAction(() => {
            this.friendsList = this.friendsList.map((friend) => {
                if(friendIds.includes(friend.id)) {
                    return {...friend, sendable: false, nowSend: false, groupId: ''};
                } else {
                    return friend;
                }
            });
            this.updateSendableFriends();

            if(this.selectedGroup) {
                this.updateGroupMember();
            }
        })

        console.log(result.message);
    }

    changeNickName = async (id: string, nickName: string) => {
        const result = await this._katalkApi.changeNickName(id, nickName);
        console.log(result);
        
        return result.success;
    }

    deleteFriend = async (friendIds: string[]) => {
        await this._katalkApi.delFriends(friendIds);

        runInAction(() => {
            this.friendsList = this.friendsList.filter((friend) => !friendIds.includes(friend.id));

            this.updateSendableFriends();

            if(this.selectedGroup) {
                this.updateGroupMember();
            }
        })
    }

    addGroup = async (groupName: string) => {
        const result = await this._katalkApi.addGroup(groupName);

        runInAction(() => {
            this.groupList.push(result);
        })
    }

    modifyGroup = async (groupId: string, groupName: string) => {
        const result = await this._katalkApi.modifyGroup(groupId, groupName);

        console.log(result);
    }

    deleteGroup = async (groupId: string) => {
        await this._katalkApi.deleteGroup(groupId);

        runInAction(() => {
            this.groupList = this.groupList.filter((group) => group.id !== groupId);

            const friendIds: string[] = [];

            this.sendFriends = this.sendFriends.map((friend) => {
                if(groupId === friend.groupId) {
                    friend.groupId = "undefined";
                    friendIds.push(friend.id);
                }
                
                return friend;
            });

            // 그룹 삭제 후 삭제한 그룹에 있던 친구들 groupId 변경
            this.moveGroup(friendIds, "undefined");
        })
    }

    moveGroup = async (friendIds: string[], groupId?: string) => {
        await this._katalkApi.changeGroup(friendIds, groupId);

        if(typeof groupId === undefined || typeof groupId === "undefined") {
            groupId = "undefined";
        }

        runInAction(() => {
            this.friendsList = this.friendsList.map((friend) => {
                if (friendIds.includes(friend.id)) {
                    if(groupId) {
                        return {...friend, sendable: true, groupId: groupId, nowSend: friend.nowSend};
                    } else {
                        return {...friend, sendable: true, nowSend: friend.nowSend};
                    }
                } else {
                    return friend;
                }
            });

            this.updateSendableFriends();

            if(this.selectedGroup) {
                this.updateGroupMember();
            }
        })
    }

    kktVersionChk = (ver: string) => {
        if(hasNewVersion(ver, this.latestVersion)) {
            this._dialogManager.alert('카톡 단체발송 프로그램이' + this.latestVersion + '버전으로 업데이트 되었습니다.','원활한 이용을 위해 현재 설치되어 있는 프로그램을 삭제한 후,\n최신 버전을 다운로드 받아 설치해 주세요.', async () => {
                window.location.href = '/katalk/KktAutoSetup.msi';
            },'최신 버전 다운로드');

            return true;
        } else {
            return false;
        }
    }

    getListFromKkt = async (list:{dup: number, name: string}[]) => {
        let tempArr = list.map((friend:{dup: number, name: string}) => ({name: friend.name, nickName: friend.name.replace(/님$/, ""), nameDup: friend.dup}));
 
        // 친구 이름으로 검색해서 가져온 데이터가 하나만 있을 경우
        if(this.kktSearchCase == '2' && tempArr.length === 1) {
            const tempData = tempArr[0];
            let isExist = false;

            // 발송명단에 포함된 목록에서 검색한 친구가 있는지 체크
            this.sendFriends.some((sf, idx) => {
                if(sf.name === tempData.name) {
                    isExist = true;
                    return true;
                }
            });

            if(isExist) {
                this._dialogManager.alert("이미 발송명단에 있는 친구입니다.");
                return;
            }
        }

        await this.addFriends(tempArr.filter((fr:any) => fr.nameDup === 0 && fr));

        await this.chkDupList(tempArr);
    }

    chkDupList = async (list:KktAutoFriendForm[]) => {
        let tempDup:KktAutoFriendForm[] = [];

        for(let i=0; i<list.length; i++) {
            if(list[i].nameDup > 0) {
                tempDup.push(list[i]);
            }
        }

        runInAction(() => {
           this.dupList = tempDup;
        })  

        if(tempDup.length > 0) {
            this._dialogManager.open(KktAddFailureListPop, {list: this.dupList});
        }
   }

    checkStateKktAuto = async () => {
        const result = await this._callKktAutoApi.callKktAutoState();

        if(result && result.status === 200) {
            switch (result.data.state) {
                case 'STARTING' :
                    this._dialogManager.alert('PC 카카오톡 프로그램을 실행해 주세요.');
                    break;
                case 'LOGGED_OUT' :
                    this._dialogManager.alert('PC 카카오톡 프로그램에 로그인해 주세요.');
                    break;
                case 'LOCKED' :
                    this._dialogManager.alert('PC 카카오톡의 잠금모드를 해제 후 다시 시도해 주세요.');
                    break;
                case 'SEND_MSG' :
                case 'GET_CONTACT' :
                    this.checkStateId = setTimeout(this.checkStateKktAuto, 500);

                    if(result.data.state === 'SEND_MSG') {
                        this.processState = 'PROCESS';
                    }

                    break;
                case 'READY' :
                    clearTimeout(this.checkStateId);
                    this.processState = 'PROCESS';

                    if(this.lastState === 'GET_CONTACT' && !this.isStopProcess) {
                        const kktFriends = await this._callKktAutoApi.getKktFriends();

                        this.getListFromKkt(kktFriends.data.list);

                    } else if(this.lastState === 'SEND_MSG') {
                        const total = result.data.sent + result.data.failed;

                        this.successCnt += result.data.sent;
                        this.failedCnt += result.data.failed;

                        // this._dialogManager.alert(`성공: ${result.data.sent}건 / ${total}건\n실패: ${result.data.failed}건 / ${total}건`);

                        if(result.data.failed > 0) {
                            const failedLog = await this._callKktAutoApi.saveFailedLog();

                            if(failedLog.status === 200) {
                                // const failedData: string[] = [];

                                failedLog.data.some((log: any) => {
                                    // failedData.push(log.name);
                                    this.failedList.push(log.name);
                                });

                                // const result = await this._katalkApi.saveFailedLog(failedData);
                                // console.log(result);
                            }
                        }

                        if(this.remainMsg && this.currSendMsgIdx != this.sendMsgList.length) {
                            await new Promise(resolve => setTimeout(resolve, 5000));
                        }

                        await this.sendStartMessage(this.sendMsgRangeIdx);
                    }

                    break;
            }

            this.lastState = result.data.state;
        }
    }

    // sendStartMessage = async (sendMsgList: KktSendMsgDto[], filename: string, filedata: string, index: number) => {
    sendStartMessage = async (index: number) => {
        // 전송할 대상이 남아있을 시
        if(index < this.sendMsgList.length) {
            let dataPart = [];

            this.sendMsgRangeIdx += this.sendRange;

            for(let i = index; i < index + this.sendRange; i++) {
                if(i >= this.sendMsgList.length) {
                    break;
                }

                dataPart.push(this.sendMsgList[i]);
                this.currSendMsgIdx++;
            }

            const result = await this._callKktAutoApi.sendStartMessage(dataPart, this.imgName, this.imgData);

            if(result.status === 200) {
                await this.checkStateKktAuto();
            }
        } else {
            // 모든 대상에게 전송 완료했을 시
            const total = this.successCnt + this.failedCnt;
            this._dialogManager.alert(`성공: ${this.successCnt}건 / ${total}건\n실패: ${this.failedCnt}건 / ${total}건`, '', () => {
                this.processState = 'COMPLETE';
            });

            if(this.failedCnt > 0) {
                const result = await this._katalkApi.saveFailedLog(this.failedList);
                console.log(result);
                this.failedList = [];
            }

            // 카카오톡 전송 관련 변수들 초기화
            this.successCnt = 0;
            this.failedCnt = 0;
            this.sendMsgList = [];
            this.sendMsgRangeIdx = 0;
            this.currSendMsgIdx = 0;
            this.remainMsg = false;
            this.imgName = "";
            this.imgData = "";
        }
    }
}