import React, { useRef, useState } from "react";
import styles from './tooltip-pop.module.scss';
import classNames from 'classnames/bind';
import { useOutsideAlerter } from "../util/util";

const cx = classNames.bind(styles);

interface TooltipPopProps {
    contents?: string | {title: string, desc: string}[];
}

const TooltipPop = ({contents}:TooltipPopProps) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const tooltipRef = useRef(null);

    useOutsideAlerter(tooltipRef, setOpenTooltip);

    return( 
        <div className={cx('tooltip-box')} ref={tooltipRef} onClick={(e) => e.stopPropagation()}>
            <button className={cx('icon-btn')} type='button' onClick={() => setOpenTooltip(!openTooltip)}></button>
            { openTooltip && 
                <div className={cx('tooltip', typeof(contents) !== 'string' && 'list')}>
                    {typeof(contents) === 'string' ?
                        contents
                        : contents?.map((con, i) => <div key={i}>
                            <p className={cx('title')}>{con.title}</p>
                            <p className={cx('desc')}>{con.desc}</p>
                        </div>)
                    }
                </div>
            }
        </div>);
};

export default TooltipPop;