import {SelectItem} from "../model/types";

export const sortList = [
    {
        id: 8,
        title: '8개씩 보기'
    },
    {
        id: 10,
        title: '10개씩 보기'
    },
    {
        id: 20,
        title: '20개씩 보기'
    },
    {
        id: 50,
        title: '50개씩 보기'
    },
];

export const sortList2 = [
    {
        id: 50,
        title: '50명씩 보기'
    },
    {
        id: 100,
        title: '100명씩 보기'
    },
    {
        id: 200,
        title: '200명씩 보기'
    },
    {
        id: 300,
        title: '300명씩 보기'
    },
    {
        id: 400,
        title: '400명씩 보기'
    },
    {
        id: 500,
        title: '500명씩 보기'
    },
];

export const orderList = [
    {
        id: 'recent',
        title: '최근 등록일 순'
    },
    {
        id: 'repNm',
        title: '고객명(가나다)순'
    },
    {
        id: 'enpNm',
        title: '기업명(가나다)순'
    },
];

export const orderListV2 = [
    {
        id: 'createAt_DESC,enpNm_DESC',
        title: '최근 등록일 순'
    },
    {
        id: 'enpNm_DESC,createAt_DESC',
        title: '기업명(가나다)순'
    },
];

export const CustomerDetailTab = {
    Personal: 'id_card',
    Summary: 'bag',
    Member: 'member',
    Financial: 'detail',
    RegCorpCopy: 'ic_copy',
    Counselling: 'report2',
    HomeTax: 'homeTax',
    PolicyFund: 'policyFund'
} as const;

export const ReportAddInfoTab = {
    Value: 'tab_icon_graph',
    StockTransfer: 'tab_icon_transfer',
    FamShare: 'tab_icon_family',
    Dividend: 'tab_icon_dividend',
    Salary: 'tab_icon_money',
    Risk: 'tab_icon_chair',
    Emergency: 'tab_icon_siren',
    Provisional: 'tab_icon_payment',
    Inheritance: 'tab_icon_hand',
    LaborerNum: 'tab_icon_laborer',
    editResultChk: 'tab_icon_result',
    MainIssue: 'tab_icon_issue'
} as const;

export const EmpSubsRefundStateList:SelectItem[] = [
    {
        id: 'ALL',
        title: '전체'
    },
    {
        id: 'WAIT_APP',
        title: '신청대기'
    },
    {
        id: 'REQUEST_REVIEW',
        title: '검토신청'
    },
    {
        id: 'COMPLETE_REVIEW',
        title: '검토완료'
    },
    {
        id: 'RECEIVE_REFUND',
        title: '환급접수'
    },
    {
        id: 'REQUEST_REFUND',
        title: '환급신청'
    },
    {
        id: 'COMPLETE_REFUND',
        title: '환급완료'
    },
];