import styles from './payment-window-pop.module.scss';
import classNames from 'classnames/bind';
import ProductBox from "./product-box";
import {useInjection} from "inversify-react";
import {DialogManager} from "./dialog-manager";
import PaymentSystemTablePop from "./payment-system-table-pop";
import {GoodApi} from "../model/goods/good-api";
import usePromise from "../model/common/use-promise";
import React from "react";

const cx = classNames.bind(styles);

const ProductListPop = ({onClose}: {onClose: VoidFunction}) => {
    const dialogManager = useInjection(DialogManager);
    const goodApi = useInjection(GoodApi);

    const [loading, goods] = usePromise(() => goodApi.currentGoods(), [goodApi]);

    return (
        <div className='full-background' onClick={onClose}>
            <div className={`pop-inner ${cx('payment-window-pop', 'pass-type')}`} onClick={(e) => e.stopPropagation()}>
                <div className={cx('title-line')}>
                    <p className={cx('title')}> 이용권 구매 상품</p>
                    <button className={cx('payment-system-table')}
                            onClick={() => dialogManager.open(PaymentSystemTablePop, {})}>이용권 요금제 비교<i></i></button>
                </div>
                {
                    (loading || !goods) ?
                        <div>Loading</div>
                        : <>
                            {
                               goods!.val!.map((goods) => <ProductBox key={goods.id} goods={goods} onClose={onClose}/>)
                            }
                        </>
                }
            </div>
        </div>
    );
}

export default ProductListPop;