import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {ApiResult} from "../api-result";
import axios from "axios";
import {CoReviewDto} from "./dto/co-review-dto";

@injectable()
export class ExtraApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getCoReview = async () => {
        return await axios.get<ApiResult<CoReviewDto[]>>(process.env.REACT_APP_API_URL + '/api/coReview');
    }
}